<template>
    <div class="annualFeeDetail">
      <embed :src="pdf" width="100%" height="100%" type="application/pdf">
    </div>
</template>

<script>
    export default {
        name: "annualFeeDetail",
        data(){
          return {
            pdf: ''
          }
        },
      mounted() {
          this.pdf = this.$route.query.url
      }
    }
</script>

<style lang="less" scoped>
    .annualFeeDetail {
      width: 100%;
      height: 100vh;
      overflow: hidden;
    }
</style>
